export const environment = {
  production: true,
  SERVER_URL: 'https://api.sellex.es/',
  FILE_ENDPOINT: 'https://api.sellex.es/api/document/upload',
  DATA_ENDPOINT: 'https://api.sellex.es/api/document/',
  APPLICATION_NAME: 'Sellex',
  LANGUAGES: ['fr', 'en', 'es'],

  // Required for cookies management
  DOMAIN: 'app.sellex.es',

  firebase: {
    apiKey: 'AIzaSyAfLF2oqpuHRRKXqMbRD439-miKDfSdrPc',
    authDomain: 'sellex-c87a8.firebaseapp.com',
    projectId: 'sellex-c87a8',
    storageBucket: 'sellex-c87a8.appspot.com',
    messagingSenderId: '226961580700',
    appId: '1:226961580700:web:fda19578c9472ef2110359',
    measurementId: 'G-PVC7C7D5RR',
  },
};
